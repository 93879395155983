<script>
import { base_url } from "@/common";
export default {
  setup() {
    return {
      base_url,
    };
  },
};
</script>

<template>
  <div class="x-bottom">
    <div class="x-bottom-container">
      <!-- 3个logo -->
      <div class="x-bottom-header">
        <div class="x-bottom-header-word">
          <div class="x-bottom-icon">
            <img :src="`${$baseUrl}/x-bottom/footer_icon_1.png`"/>
          </div>
          <div class="text">互联网大厂总监级大咖手把手教学</div>
        </div>
        <div class="x-bottom-header-word">
          <div class="x-bottom-icon">
            <img :src="`${$baseUrl}/x-bottom/footer_icon_2.png`"/>
          </div>
          <div class="text">企业级开发项目课时饱满性价比高</div>
        </div>
        <div class="x-bottom-header-word">
          <div class="x-bottom-icon">
            <img :src="`${$baseUrl}/x-bottom/footer_icon_3.png`"/>
          </div>
          <div class="text">终身就业支持为职业生涯保驾护航</div>
        </div>
      </div>

      <!-- 分割线 -->
      <div class="x-bottom-divider"></div>
      
      <!-- 关于我们 服务展示 联系我们 -->
      <div class="x-bottom-content-container">
        <div class="x-bottom-content-left">
          <div class="text">
            <h3>关于我们</h3>
            <div>教研团队</div>
            <div>合作资讯</div>
          </div>
          <div class="text">
            <h3>服务展示</h3>
            <div>就业保障</div>
            <div>学员风采</div>
          </div>
          <div class="text">
            <h3>联系我们</h3>
            <div>咨询电话：15501194419</div>
            <div>总部地址：西安市长安区西长安街869号万科城如园一单元三楼</div>
          </div>
        </div>
        <div class="x-bottom-content-right">
          <!-- 20240814付小荣要求修改 -->
          <div class="qr-code">
            <img :src="`${$baseUrl}/x-bottom/qr_left.jpg`"/>
            <div class="text">识别二维码<br/>即刻咨询</div>
          </div>
          <!-- <div class="qr-code">
            <img :src="`${$baseUrl}/x-bottom/qr_right.png`"/>
            <div class="text">加入技术分享群</div>
          </div> -->
        </div>
      </div>
    </div>
    <div class="footer">
      <span>Copyright&copy;2010-2024 Corporation All Rights Reserved.陕西文都智链计算机科技有限公司 版权所有</span>
      <a href="https://beian.miit.gov.cn/" target="blank" >陕ICP备16004086号-7</a>
    </div>

  </div>
</template>



<style scoped>
@import url(./XBottom.css);
</style>
