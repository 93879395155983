<template>
  <div class="jytd">
    <div class="jytd-container">
      <div class="jytd-log">
        <img :src="`${$baseUrl}/jytd/Slice 8@2x.png`" />
      </div>


      <div class="title-kctx">
        <div class="index-title">
          <span class="title_cn_left">文都智链</span><span class="title_cn_right">师资力量</span>
          <div class="title_en">TEACHER RESOURCES</div>
        </div>
      </div>


      <div class="jytd-list">
        <div class="jytd-text">

          <p>
            文都集团大学教育教学研究院（简称“集团教研院”）是世纪文都集团，从事大学教育教研开发、教学管理，师资发展、师资培训与资源整合的特设机构。集团教研院下设西北分院、湖北分院、北京分院、河南分院等二十余家教研分院。目前，教研院在全国拥有教师团队600多人，其中85%毕业于985、211全国重点院校。
          </p>
          <p>
            为实现高新技术快速高质量的转化为教育产品，文都智链针对不同学科，与行业领军企业、行业协会、高校教育专家、企业技术总监等行业领军人物建立教学研究院，对专业建设、课程研发、师资培训和教学提供支持和指导，实现了产业技术到教育产品的转化和教育产品与院校实时的融合。
          </p>
        </div>
        
        <div class="jytd-item" v-for="tea in teacherList" :key="tea.id">
          <img class="img1" src="~@/assets/page/jytd/Slice 9@2x.png">
          <div class="jytd-img">
            <img class="imgbg" src="~@/assets/page/jytd/Slice 15@2x.png">
            <img class="imgtea" :src="`${$apiBaseUrl}${tea.avatarJytd}`" />
          </div>
          <div class="jytd-info">
            <div class="jytd-info-name">
              <span :style="teacherName(0).style" v-text="tea.name" ></span>
              <span :style="teacherName(0).levelStyle" v-text="tea.level" ></span>
            </div>

            <div class="jytd-info-feature">
              <span>授课风格</span>
              <span v-text="tea.feature.join(',')" ></span>
            </div>
            <div class="jytd-info-intro">
              <span>讲师简介</span>
              <span v-text="tea.intro"></span>
            </div>
          </div>
        </div>
      </div>


    </div>
  </div>
</template>

<script>
const teacherList = [];//师资列表
const getStyle = [
  {
    style: { fontWeight: 'bold', color: '#1C70FF', fontSize: '22px', textAlign: 'justify', textAlignLast: 'justify', width: '76px', marginRight: '30px' },
    levelStyle: { fontFamily: '黑体', color: '#333' }
  },

  {
    style: { fontWeight: 'bold' },
    levelStyle: { fontFamily: '黑体', marginLeft: '1px' }
  }
]

const getTeacherList = async function () {
  const res = await this.$httpGet('front/teacher/list_jytd')
  this.teacherList = res.data.map(t => {
    const arr = t.feature && t.feature.split(",")
    return { ...t, feature: (arr instanceof Array ? arr : [arr]) }
  })
  this.teacherList.sort(() => Math.random() - 0.5);
  //console.log(this.teacherList)
}
const teacherName = function (idx) {
  let len = this.teacherList[idx] && this.teacherList[idx].name.length;
  //console.log(len)
  if (len == 3) {
    return getStyle[0]
  } else {
    return getStyle[1]
  }
}
export default {
  data() {
    return {
      teacherList, getStyle
    }
  },
  created() {
    this.getTeacherList()
  },
  methods: {
    getTeacherList, teacherName
  }
}
</script>

<style scoped>
@import url('./jytd.css');
</style>